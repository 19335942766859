<template>
    <div class="mobile">
      <div class="main-content">
        <div class="text-content" >
          <slot name="content" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'index',
    props: ['menuText', 'date'],
    data() { 
      return {
  
      }
    },
    components:{
    },
    created() {
  
    },
    methods:{
  
    },
   }
  </script>
  
  <style lang='scss' scoped>
  @function px2rem($px, $screen: 1920) {
    @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
  }
  
  @function px2vw($px) {
    @return calc(100vw * #{$px} / 1920)
  }
  .mobile {
    background-color: #fff;
    padding-bottom: px2rem(30, 375);
  
  
    .main-content {
      width: 90vw;
      padding: px2rem(25, 375) px2rem(20, 375);
      padding-top: px2rem(10, 375);
      overflow-y: scroll;
      margin: auto;
  
      .time {
        font-size: px2rem(12, 375);
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: px2rem(20, 375);
        text-align: right;
      }
      /deep/ .text-content {
        div {
          p:nth-child(1) {
            strong{
              span {
                font-size: 20px !important;
              }
            }
            img {
                width: 100%;
                height: 100%;
            }
          }
          p:nth-child(2) {
            strong{
              span {
                font-size: 16px !important;
              }
            }
          }
          p:nth-child(3) {
            span {
              font-size: 12px !important;
            }
          }
          p {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            span {
              font-size: 14px !important;
            }
          }
          table {
            width: 100% !important;
            margin: auto;
          }
        }
      }
      div {
        p{
            border-collapse: collapse;
            border-width: 0px;
            border-spacing: 0px;
            border-color: rgb(252, 224, 200);
            margin-left: auto;
            margin-right: auto;
        }
      }
    }
  
  
    .bottom {
      width: 100%;
      background-color: #fff;
      padding: px2rem(10, 375) 0;
      position: absolute;
      bottom: 0;
      z-index: 9999;
      .qrcode {
        display: flex;
        justify-content: space-evenly;
        margin: px2rem(30, 375) 0;
        display: none;
  
        .item {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          img {
            width: px2rem(100, 375);
            height: px2rem(100, 375);
          }
          span {
            font-size: px2rem(13, 375);
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }
        }
      }
  
      .info {
        p {
          font-size: px2rem(13, 375);
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: center;
  
          &.beian {
            color: RGBA(38, 77, 139, 1);
          }
        }
      }
    }
  }
  </style>
<template>
  <div class="mobile">
    <header>
      <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
    </header>
    <div class="menus">
      <span @click="$router.push('/')">首页</span>&nbsp;>
      {{ menuText }}
    </div>
    <div class="main-content">
      <div class="time" v-if="date">最新信息披露日期：{{ date }}</div>
      <div class="text-content" >
        <slot name="content" />
      </div>
    </div>
    <div class="bottom">
        <div class="qrcode">
          <div class="item">
            <img src="~@/static/images/public.jpg" alt="" />
            <span>公众号：安联人寿</span>
          </div>
          <div class="item">
            <img src="~@/static/images/service.jpg" alt="" />
            <span>服务号-安联在线</span>
          </div>
        </div>
        <div class="info">
          <p>© 安联人寿保险有限公司2012.All Rights Reserved.</p>
          <p class="beian">沪ICP备07011309号-1</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: ['menuText', 'date'],
  data() { 
    return {

    }
  },
  components:{
  },
  created() {

  },
  methods:{

  },
 }
</script>

<style lang='scss' scoped>
@function px2rem($px, $screen: 1920) {
  @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
}

@function px2vw($px) {
  @return calc(100vw * #{$px} / 1920)
}
.mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  padding-bottom: px2rem(30, 375);

  header {
    width: 100%;
    height: px2rem(50, 375);
    display: flex;
    align-items: center;
    padding-left: px2rem(15, 375);
    img {
      width: px2rem(75, 375);
      height: px2rem(30, 375);
    }
  }

  .menus {
    width: 100%;
    height: px2rem(35, 375);
    background-color: rgba(0, 55, 129, 1);
    display: flex;
    align-items: center;
    font-size: px2rem(12, 375);
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    padding-left: px2rem(15, 375);
  }

  .main-content {
    width: 100%;
    padding: px2rem(25, 375) px2rem(20, 375);
    padding-bottom: px2rem(120, 375);
    height: 100%;
    overflow-y: scroll;

    .time {
      font-size: px2rem(12, 375);
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: px2rem(20, 375);
      text-align: right;
    }
    /deep/ .text-content {
      div {
        p:nth-child(1) {
          strong{
            span {
              font-size: 20px !important;
            }
          }
        }
        p:nth-child(2) {
          strong{
            span {
              font-size: 16px !important;
            }
          }
        }
        p:nth-child(3) {
          span {
            font-size: 12px !important;
          }
        }
        p {
          margin-top: 0px !important;
          margin-bottom: 0px !important;
          span {
            font-size: 14px !important;
          }
        }
      }
    }
  }


  .bottom {
    width: 100%;
    background-color: #fff;
    padding: px2rem(10, 375) 0;
    position: absolute;
    bottom: 0;
    z-index: 9999;
    .qrcode {
      display: flex;
      justify-content: space-evenly;
      margin: px2rem(30, 375) 0;
      display: none;

      .item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        img {
          width: px2rem(100, 375);
          height: px2rem(100, 375);
        }
        span {
          font-size: px2rem(13, 375);
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }
    }

    .info {
      p {
        font-size: px2rem(13, 375);
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;

        &.beian {
          color: RGBA(38, 77, 139, 1);
        }
      }
    }
  }
}
</style>